.carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden; /* Ensures only the active slide is visible */
  }
  
  .carousel-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }
  
  .carousel-container img {
    flex: 0 0 100%; /* Each image takes full width of the container */
    object-fit: cover; /* Ensures images cover the area without distortion */
  }
  
  .carousel-container button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    cursor: pointer;
    font-size: 24px;
    z-index: 100;
    padding: 10px;
    border-radius: 50%;
  }
  
  .carousel-container button.left {
    left: 10px;
  }
  
  .carousel-container button.right {
    right: 10px;
  }
  